import React from 'react'
import logo from '../assets/images/logo.svg'

class Header extends React.Component {
  render() {
    return (
      <section id="header">
        <div className="inner">
          <img src={logo} className="sk-logo" alt="SK-UK logo" />
        </div>
      </section>
    )
  }
}

export default Header
